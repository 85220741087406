import React from 'react'
import './Header.css'

const Header = ({ logo }) => {
    return (
        <header>
            <div className="div1">
                <img src={logo} alt="" className='logo' />
            </div>
            <div className="div2">
                <main className='pages'>
                    <a href="#aboutme">
                        <p className='header-links' >About me</p>
                    </a>
                    <a href="#github">
                        <p className='header-links'>GitHub</p>
                    </a>

                    <a href="#contact">
                        <p className='header-links'>Contact me</p>
                    </a>
                </main>
            </div>
            <div className="div3">
                {/* <button className='login' onClick={LogBtn}>
                    {login}
                    {login ? (<p> Log Out</p>) : (<p>Sign In</p>)}
                </button> */}
            </div>
        </header>
    )
}

export default Header
