import './Res.css'
import { IoLogoJavascript } from "react-icons/io5";
import { SiLua } from "react-icons/si";
import { GiCancel } from "react-icons/gi";

const Res = ({ project }) => {
    const launageIcon = (launage) => {
        switch (launage) {
            case 'JavaScript':
                return <IoLogoJavascript style={{ color: 'yellow' }} />;
            case 'Lua':
                return <SiLua style={{ color: 'violet' }} />
            case '':
                return <GiCancel />

            default:
                return <GiCancel />
        }
    }
    return (
        <div className="project">

            <h5>{project.name}</h5>

            <img src={project.owner.avatar_url} className='profile' alt="" />
            <p className='vib'>{project.visibility.toUpperCase()}</p>

            <p className='stats' >Stars : {project.stargazers_count}</p>
            <a href={project.html_url} target='_blank' rel="noreferrer" className='view'>
                <button className='view'> <p className='res-text'> View Project </p> </button>
            </a>
            <p className='stats'>Watchers : {project.watchers_count}</p>


            {
                project.language ? (
                    <p className='language'>
                        {launageIcon(project.language)} {project.language}
                    </p>
                ) : (<p className='language'>
                    None
                </p>)
            }



        </div >
    )
}
export default Res