import React from 'react';
import './About.css';

const About = ({ img, imgalt }) => {
    return (
        <div className="about" id='aboutme'>
            <div className="header">
                <p>About me</p>
            </div>
            <div className="btn"></div>

            <div className="img">
                <img className="image" src={img} alt={'imgalt'} />
                <div className='image-center'>
                    <p className='about-center'>
                        16 rokov
                    </p>
                </div>
            </div>
            <div className="text">
                <text className='aboutme'>
                    Ahoj volám sa Adam Hyben a ty si práve na mojej stránke. Túto stránku som programoval sám a mojim hlavným jazykom bol Javascript. Študujem odbor IT a venujem sa programovaniu už nejakú chvíľku.
                </text>
            </div>
        </div>
    )
}

export default About
