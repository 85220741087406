import logo from './logo.svg';
import img from './assets/me.jpg';
import './App.css';
import Header from './header/Header';
import About from './aboutme/About';
import Github from './github/Github';

function App() {
  // const img = 'https://cdn.discordapp.com/attachments/771360152141168672/1242132004628201535/C79EAFFA-1AC7-4CD6-88A2-2FEB08A582DC.jpg?ex=664cb913&is=664b6793&hm=df049f24b62b35eb10cf0de7d29c963c27c0e10ffca5ebddff5bb5777a201403&'


  return (
    <div className='main'>
      <Header
        logo={logo}
      />
      <About
        img={img}
        imgalt={"Creator of this page in Tv studio learning how to use big TV camera"}
      />
      <Github
        logo={logo}
      />

    </div>
  );
}

export default App;
