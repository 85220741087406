import { useState, useEffect } from 'react';
import Res from './Res'
import './Github.css'

function Github({ logo }) {
  const [IsLoading, SetIsLoading] = useState('true')
  const [projects, Setprojects] = useState([])

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await fetch(`https://api.github.com/users/habybyi/repos?1&per_page=10=10&sort=updated`)
        if (!res.ok) throw Error("something went wrong with github API")
        const data = await res.json()
        Setprojects(data)
        if (res.ok) {
          SetIsLoading(false)
        }
      } catch (err) {
        console.log(err)
      }
    }
    setTimeout(() => {
      fetchProjects()
    }, 3000);
  })

  return (
    <selection className='resp' id='github'>
      <div className="title">
        <p>GitHub Projects</p>
      </div>
      <div className="pr">
        <div className="projects">
          {projects && !IsLoading ? (
            projects.map((project) => (
              project.visibility !== 'private' && (
                <Res key={project.id} project={project} />
              )
            ))) : (
            < div className="loading" >
              <img src={logo} className="App-logo" alt={logo} />
              <h1>Loading GitHub API...</h1>
            </div>
          )
          }
        </div>
      </div>
    </selection >
  );
}

export default Github;
